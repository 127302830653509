import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {LoadingOutlined} from "@ant-design/icons";
import {PageRequestDto, UpdateProjectWorkersDto, UserDto} from 'shared/generated-sources';

import ModalComponent from 'components/ModalComponent';
import {useGetUsersQuery} from 'projects/api/ProjectApi';

interface addWorkersOnProjectModalProps {
    onSubmit: (values: UpdateProjectWorkersDto) => void;
    onClose: () => void;
    projectId: string
}

const AddWorkersOnProjectModal: React.FC<addWorkersOnProjectModalProps> = ({ onSubmit, onClose, projectId }) => {
    
    const [users, setUsers] = useState<UserDto[]>([]);

    const { data, isLoading, isSuccess } = useGetUsersQuery({
        pageRequest: { pageNumber: 0, pageSize: 10, sortBy: "firstName", sortDirection: PageRequestDto.sortDirection.ASC },
        notInProject: projectId
    }, {refetchOnMountOrArgChange : true});

    useEffect(() => {
        if (isSuccess) {
            setUsers(data.data || []);

        }
    }, [isSuccess, data]);

    if (isLoading) {
        return <Spin indicator={<LoadingOutlined spin />} size="large" />
    }

    const userOptions = users.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
    }));

    return (
        <ModalComponent<UpdateProjectWorkersDto>
            open
            name="Add Workers"
            onClose={onClose}
            onSubmit={onSubmit}
        >
            <Form.Item
                name="usersIds"
                label="Users"
                style={{ marginBottom: '200px' }}
                rules={[{ required: true, message: 'Search for workers' }]}
            >
                <Select
                    mode="tags"
                    style={{
                        width: '100%',
                    }}
                    tokenSeparators={[',']}
                    options={userOptions}
                />
            </Form.Item>
        </ModalComponent>
    );
};

export default AddWorkersOnProjectModal;
