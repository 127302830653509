import { useLocation, useNavigate } from "react-router";
import { Menu, MenuProps } from "antd";
import { getCurrentUserRole } from "store/store";
import { useSelector } from "react-redux";
import { MailOutlined, UserOutlined, FundOutlined, TeamOutlined } from '@ant-design/icons';

import { navigation } from "shared/constants/Routes";
import { RoutePathKeys } from "shared/constants/RoutePathKeys.enum";
import { RoleDto } from "shared/generated-sources";


const SidenavComponent = () => {

    type MenuItem = Required<MenuProps>['items'][number];

    const navigate = useNavigate();
    const location = useLocation();

    const userRole = useSelector(getCurrentUserRole)[0];
    const children = [];
    if (userRole === RoleDto.name.WORKER || userRole === RoleDto.name.DIRECTOR) {
        children.push({
            key: RoutePathKeys.workerProjects,
            label: 'Worker view'
        });
    }

    if (userRole === RoleDto.name.DIRECTOR) {
        children.push(
            {
                key: RoutePathKeys.directorProjects,
                label: 'Director view'
            }
        );
    }

    if (userRole === RoleDto.name.ADMIN || userRole === RoleDto.name.ACCOUNTANT) {
        children.push(
            {
                key: RoutePathKeys.projects,
                label: 'Projects view'
            })
    }

    const handleMenuClick = ({ key }: { key: string }) => {
        const { target } = navigation.authorized.find(item => item.key === key) || {};

        if (target) {
            navigate(target);
        }
    };

    const items: MenuItem[] = [
        {
            key: RoutePathKeys.dashboard,
            label: 'Dashboard',
            icon: <MailOutlined />
        },
        {
            key: RoutePathKeys.profile,
            label: 'Profile',
            icon: <UserOutlined />
        },
        {
            key: RoutePathKeys.users,
            label: 'Users',
            icon: <TeamOutlined />
        },

        ...(userRole === RoleDto.name.ADMIN || userRole === RoleDto.name.ACCOUNTANT
            ? [
                {
                    key: RoutePathKeys.accountant,
                    label: 'Accountant',
                    icon: <FundOutlined />
                }
            ]
            : []),
        {
            key: 'projects',
            label: 'Projects',
            type: 'group',
            children: children,
        },

    ];

    return (
        <Menu
            onClick={handleMenuClick}
            style={{ width: 194 }}
            defaultOpenKeys={['sub1']}
            selectedKeys={[location.pathname]}
            defaultSelectedKeys={[RoutePathKeys.dashboard]}
            mode="inline"
            items={items}
        />
    )
}

export default SidenavComponent;
