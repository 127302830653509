/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserDto } from './UserDto';
import type { WorkPackageDto } from './WorkPackageDto';
export type ProjectDto = {
    id: string;
    name: string;
    startDate: string;
    endDate: string;
    status: ProjectDto.status;
    director: UserDto;
    workers: Array<UserDto>;
    workPackages: Array<WorkPackageDto>;
    allocatedPersonMonths: number;
};
export namespace ProjectDto {
    export enum status {
        ACTIVE = 'ACTIVE',
        DELETED = 'DELETED',
        INACTIVE = 'INACTIVE',
    }
}

