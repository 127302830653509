import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store/store';

const httpRequestHandler = fetchBaseQuery( {
                                               baseUrl       : `${ process.env.REACT_APP_API_URL }${ process.env.REACT_APP_API_VERSION }`,
                                               prepareHeaders: (headers, { getState }) => {
                                                   const state = getState() as RootState;
                                                   const token = state.authentication.token;

                                                   if( token ) {
                                                       headers.set( 'Authorization', `Bearer ${ token }` );
                                                   }

                                                   return headers;
                                               }
                                           } );

export default httpRequestHandler;
