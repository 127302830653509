import React from 'react';
import { Form, Input } from 'antd';

import { CreateTaskDto } from 'shared/generated-sources';

import ModalComponent from 'components/ModalComponent';

interface AddTaskModalProps {
    projectStartDate: string;
    projectEndDate: string;
    onSubmit: (values: CreateTaskDto) => void;
    onClose: () => void;
}

const AddTaskModal: React.FC<AddTaskModalProps> = ({ projectStartDate, projectEndDate, onSubmit, onClose }) => {

    return (
        <ModalComponent<CreateTaskDto>
            open
            name="Add Task"
            onClose={onClose}
            onSubmit={onSubmit}
        >
            <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Please input a name!' }]}
            >
                <Input type={"string"} />
            </Form.Item>
            <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: 'Type in a description' }]}
            >
                <Input type={"string"} />
            </Form.Item>
            <Form.Item
                name="startDate"
                label="Start date"
                rules={[{ required: true, message: 'Choose a date', type: 'date' }]}
            >
                <Input type={"date"}
                    min={projectStartDate}
                    max={projectEndDate} />
            </Form.Item>
            <Form.Item
                name="endDate"
                label="End date"
                rules={[{ required: true, message: 'Choose a date', type: 'date' }]}
            >
                <Input type={"date"}
                    min={projectStartDate}
                    max={projectEndDate} />
            </Form.Item>
        </ModalComponent>
    );
};

export default AddTaskModal;