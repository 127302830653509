import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Form, Input, Typography } from "antd";
import { usePasswordResetMutation } from "auth/api/AuthApi";
import { ResetPasswordDto } from "shared/generated-sources";

const PasswordResetPage = () => {
    const [passwordReset] = usePasswordResetMutation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const emailFromParams = searchParams.get("email");

    const handlePasswordReset = async (value: ResetPasswordDto) => {
        const response = await passwordReset(value);
        if (!response.error) {
            navigate("/login");
        }
    };

    return (
        <Form
            name="passwordReset"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={handlePasswordReset}
            autoComplete="off"
        >
            <Typography.Title level={3} style={{ textAlign: "center" }}>
                Password Reset
            </Typography.Title>

            <Form.Item
                label="Email"
                name="email"
                initialValue={emailFromParams}
                rules={[{ required: true, message: "Email is required!" }]}
            >
                <Input disabled />
            </Form.Item>

            <Form.Item
                label="Code"
                name="code"
                rules={[{ required: true, message: "Code is required!" }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                    { required: true, message: "Please input your new password!" },
                    { min: 8, message: "Password must be at least 8 characters long!" },
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                dependencies={["newPassword"]}
                rules={[
                    { required: true, message: "Please confirm your password!" },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue("newPassword") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error("Passwords do not match!"));
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default PasswordResetPage;
