/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PageRequestDto = {
    pageNumber?: number;
    pageSize?: number;
    sortDirection?: PageRequestDto.sortDirection;
    sortBy?: string;
};
export namespace PageRequestDto {
    export enum sortDirection {
        ASC = 'asc',
        DESC = 'desc',
    }
}

