import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { handleQueryStartedFunction } from '../../shared/functions/handleQueryStarted.function';
import { LoginResponseDto, LoginUserDto, RequestResetPasswordDto, ResetPasswordDto, SuccessDto } from '../../shared/generated-sources';
import { userLoggedIn } from './AuthSlice';

export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
    endpoints: (builder) => (
        {
            loginUser: builder.mutation<LoginResponseDto, LoginUserDto>({
                query: (data: LoginUserDto) => (
                    {
                        url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}/auth/login`,
                        method: 'POST',
                        body: data
                    }
                ),
                onQueryStarted: (arg, api) => handleQueryStartedFunction<LoginResponseDto>(
                    {
                        dispatch: api.dispatch,
                        successActions: [userLoggedIn],
                        queryFulfilled: api.queryFulfilled
                    }
                ),
            }),
            passwordResetRequest: builder.mutation<SuccessDto, RequestResetPasswordDto>({
                query: (data: RequestResetPasswordDto) => (
                    {
                        url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}/auth/passwordResetRequest`,
                        method: 'POST',
                        body: data
                    }
                )
            }),
            passwordReset: builder.mutation<SuccessDto, ResetPasswordDto>({
                query: (data: RequestResetPasswordDto) => (
                    {
                        url: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VERSION}/auth/passwordReset`,
                        method: 'POST',
                        body: data
                    }
                )
            })
        }

    )
});

export const { useLoginUserMutation, usePasswordResetRequestMutation, usePasswordResetMutation } = authApi;
