import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button, Card, Dropdown, Flex, Spin, Tooltip } from "antd";
import { LoadingOutlined, UserAddOutlined, UserDeleteOutlined, TeamOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import type { MenuProps } from 'antd';

import { getCurrentUserEmail } from "store/store";
import { useAddWorkPackageMutation, useGetProjectQuery, useUpdateWorkPackageMutation, useDeleteWorkPackageMutation, useAddWorkersToProjectMutation, useDeleteWorkersFromProjectMutation } from "projects/api/ProjectApi";
import { CreateWorkPackageDto, ProjectDto, UpdateWorkPackageDto, UpdateProjectWorkersDto, WorkPackageDto, UserDto } from "shared/generated-sources";
import { useModal } from "shared/contexts/ModalContext";

import AddWorkPackageModal from "components/modals/AddWorkPackageModal";
import UpdateWorkPackageModal from "components/modals/UpdateWorkPackageModal";
import TableComponent from "components/TableComponent";
import TasksComponent from "components/TasksComponent";

import AddWorkersOnProjectModal from "components/modals/AddWorkersOnProjectModal";
import RemoveWorkersFromProjectModal from "components/modals/RemoveWorkersFromProjectModal";

const ProjectPage = () => {
    const { showModal, hideModal } = useModal();

    const [project, setProject] = useState<ProjectDto | undefined>(undefined);

    const [addWorkPackage] = useAddWorkPackageMutation();
    const [updateWorkPackage] = useUpdateWorkPackageMutation();
    const [deleteWorkPackage] = useDeleteWorkPackageMutation();

    const [addWorkers] = useAddWorkersToProjectMutation();
    const [removeWorkers] = useDeleteWorkersFromProjectMutation();
    const { id } = useParams();

    const { data, isLoading, isSuccess } = useGetProjectQuery({ id: id! }, { refetchOnMountOrArgChange: true });

    const userEmail = useSelector(getCurrentUserEmail);

    useEffect(() => {
        if (isSuccess) {
            setProject(data);
        }
    }, [isSuccess, data]);

    function findWorkPackageById(workPackageId: string): WorkPackageDto | undefined {
        return project?.workPackages.find(workPackage => workPackage.id === workPackageId);
    }

    const handleAddWorkPackage = async (projectId: string, workPackage: CreateWorkPackageDto) => {

        const response = await addWorkPackage({ projectId: projectId, workPackage });
        setProject(response.data!);
    }

    const handleUpdateWorkPackage = async (workPackageId: string, workPackage: UpdateWorkPackageDto) => {
        const project = await updateWorkPackage({ projectId: id!, workPackageId, workPackage });
        setProject(project.data as ProjectDto);
    }

    const handleDeleteWorkPackage = async (record: { id: string }) => {
        const project = await deleteWorkPackage({ projectId: id!, workPackageId: record.id });
        setProject(project.data!);
    }

    const handleExpandWorkpackage = (record: { id: string, reference: number }) => {

        return (
            <TasksComponent
                project={project}
                projectId={id!}
                workpackageId={record.id}
                workpackageRef={record.reference}
            />
        );
    };

    const handleAddWorkerToProject = async (usersIds: UpdateProjectWorkersDto) => {
        const project = await addWorkers({ projectId: id!, usersIds: usersIds })
        setProject(project.data)
    }

    const handleDeleteWorkerFromProject = async (usersIds: UpdateProjectWorkersDto) => {
        const project = await removeWorkers({ projectId: id!, usersIds: usersIds })
        setProject(project.data)
    }

    const openAddWorkersToProjectModal = () => {

        showModal(
            "addWorkersToProjectModal",
            <AddWorkersOnProjectModal
                onClose={() => hideModal("addWorkersToProjectModal")}
                onSubmit={(values) => {
                    handleAddWorkerToProject(values);
                    hideModal("addWorkersToProjectModal");
                }}
                projectId={project!.id}

            />
        );
    };

    const openDeleteWorkersToProjectModal = () => {

        showModal(
            "removeWorkersFromProjectModal",
            <RemoveWorkersFromProjectModal
                onClose={() => hideModal("removeWorkersFromProjectModal")}
                onSubmit={(values) => {
                    handleDeleteWorkerFromProject(values)
                    hideModal("removeWorkersFromProjectModal");
                }}
                workers={project!.workers}
            />

        );
    };

    const openAddWorkPackageModal = (projectId: string) => {

        showModal(
            "addWorkPackageModal",
            <AddWorkPackageModal
                onClose={() => hideModal("addWorkPackageModal")}
                onSubmit={(values) => {
                    handleAddWorkPackage(projectId, values).then(data => {
                        hideModal("addWorkPackageModal")
                    })
                }}
            />
        );
    };

    const openUpdateWorkPackageModal = (record: { id: string }) => {
        const currentWorkPackage = findWorkPackageById(record.id);

        showModal(
            "updateWorkPackageModal",
            <UpdateWorkPackageModal
                onClose={() => hideModal("updateWorkPackageModal")}
                onSubmit={(values) => {
                    handleUpdateWorkPackage(record.id, values).then(data => {
                        hideModal("updateWorkPackageModal")
                    })
                }}
                initialValues={{
                    name: currentWorkPackage ? currentWorkPackage.name : "",
                    reference: currentWorkPackage ? currentWorkPackage.reference : 0,
                    description: currentWorkPackage ? currentWorkPackage.description : "",
                    status: currentWorkPackage ? currentWorkPackage.status : UpdateWorkPackageDto.status.INACTIVE,
                    //allocatedPersonMonths: currentWorkPackage ? currentWorkPackage.allocatedPersonMonths : 0
                }}
            />
        );
    };

    const PROJECT_COLUMNS = [
        {
            title: 'Ref',
            dataIndex: 'reference',
            key: 'reference',
            onCell: () => ({
                style: { width: '5vw' }
            }),
            render: (text: string) => `WP${text}`,
        },
        {

            title: 'Person Months',
            dataIndex: 'numberOfPersonMonths',
            key: 'numberOfPersonMonths',
            onCell: () => ({
                style: { width: '10vw' }
            }),
        },
        {

            title: 'Work packages',
            dataIndex: 'name',
            key: 'name',
            onCell: () => ({
                style: { width: '50vw' }
            }),
        },

    ]

    const items: MenuProps['items'] = [
        {
            key: '0',
            type: 'group',
            label: 'Director',
            children: [
                {
                    label: project?.director.email || "No director",
                    key: project?.director.id || "No director",
                    className: 'nonHoverable',
                },
            ]
        },
        {
            type: "divider"
        },
        {
            key: '1',
            type: 'group',
            label: 'Workers',
            children: [
                ...(project?.workers || []).map((worker: UserDto) => ({
                    label: worker.email,
                    key: worker.id,
                    className: 'nonHoverable',
                }))
            ]
        },
    ]

    if (isLoading) {
        return <Spin indicator={<LoadingOutlined spin />} size="large" />
    }

    return (
        <>
            {project !== undefined ?
                <div>
                    <Card title={"Project: " + project!.name} bordered={false} extra={
                        <Flex gap="large">
                            <Dropdown
                                menu={{
                                    items
                                }}
                                trigger={['hover']}
                            >
                                <Button type="primary">
                                    <TeamOutlined style={{ fontSize: '200%' }} />
                                </Button>
                            </Dropdown>
                            {userEmail === project.director.email &&
                                <>
                                    <Tooltip placement="bottom" title={"Add worker to project"}>
                                        <Button type="primary" onClick={openAddWorkersToProjectModal}>
                                            <UserAddOutlined style={{ fontSize: '200%' }} />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip placement="bottom" title={"Remove worker from project"}>
                                        <Button type="primary" onClick={openDeleteWorkersToProjectModal}>
                                            <UserDeleteOutlined style={{ fontSize: '200%' }} />
                                        </Button>
                                    </Tooltip>
                                </>}
                        </Flex>
                    }>
                        <TableComponent
                            pagination={{ current: 0, total: 0 }}
                            data={data ? project.workPackages : []}
                            columns={PROJECT_COLUMNS}
                            onAdd={() => openAddWorkPackageModal(project.id)}
                            tooltip="workpackage"
                            isWorkerViewing={userEmail !== project?.director.email}
                            onEdit={openUpdateWorkPackageModal}
                            onDelete={handleDeleteWorkPackage}
                            expandComponent={(record) => handleExpandWorkpackage(record)}
                        />
                    </Card>
                </div>
                :
                <div>
                    <Card title="Project not found" bordered={false}>
                    </Card>
                </div>}
        </>
    )
}

export default ProjectPage;


const customStyles = `
    .nonHoverable {
        pointer-events: none; /* Makes it non-clickable */
        color: black !important; /* Ensures the text stays black */
        background-color: transparent !important; /* Keeps the background neutral */
        cursor: default !important; /* No pointer cursor */
    }
    .nonHoverable:hover {
        background-color: transparent !important; /* Remove hover background */
    }
`;

// Dynamically inject styles into the DOM
const styleTag = document.createElement("style");
styleTag.innerHTML = customStyles;
document.head.appendChild(styleTag);