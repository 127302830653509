import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Dropdown, MenuProps, Tooltip, Divider, Menu } from 'antd';
import { ClockingDto, CreateClockingDto, FreeDayDto, FreeDayParamDto, TaskDto, UserDto } from 'shared/generated-sources';
import ModalComponent from 'components/ModalComponent';
import dayjs, { Dayjs } from 'dayjs';
import CalendarComponent from 'components/CalendarComponent';
import { useAddClockingsMutation, useGetFreeDaysQuery, useLazyGetClockingQuery } from 'projects/api/ProjectApi';

interface AddClockingModalProps {
    users: UserDto[];
    startDate: Dayjs;
    endDate: Dayjs;
    task: TaskDto | null;
    projectId: string;
    workPackageId: string;
    onSubmit: (values: CreateClockingDto[], date: string) => void;
    onClose: () => void;
}
interface FormValues {
    [key: string]: string | number;
}

const AddClockingModal: React.FC<AddClockingModalProps> = ({ projectId, workPackageId, users, startDate, endDate, task, onSubmit, onClose }) => {

    const [selectedDate, setSelectedDate] = useState<Dayjs>();
    const [selectedDates, setSelectedDates] = useState<FreeDayDto[]>([]);
    const [currentDayTaskClocking, setCurrentDayTaskClocking] = useState<ClockingDto[]>([]);
    const [userTotalHours, setUserTotalHours] = useState<Record<string, number>>({});

    const [addClocking] = useAddClockingsMutation();

    const handleAddClockings = async (clocking: CreateClockingDto[]) => {
        await addClocking({ projectId: projectId, workPackageId: workPackageId, taskId: task ? task.id : "", clockings: clocking });
    }
    const [triggerGetClocking, { data: clockingData, isLoading: isLoadingClocking, isSuccess: isSuccessClocking }] = useLazyGetClockingQuery();

    const freeDayParams: FreeDayParamDto = {
        month: (startDate.month() + 1),
        year: startDate.year(),
    }

    const clockingBreakdown: MenuProps['items'] = [
    ];

    const handleDateSelect = (value: Dayjs) => {
        const formattedDate = value.format("YYYY-MM-DD");
        const formattedSelectedDate = selectedDate?.format("YYYY-MM-DD");

        if (formattedDate !== formattedSelectedDate) {
            setSelectedDate(value);
            getClockingData(formattedDate)
        }
    }
    
    const getClockingData = async (formattedDate: string) => {
        try {
            const response = await triggerGetClocking({
                clockingParams: {
                    taskId: task?.id || '',
                    startDate: formattedDate,
                    endDate: formattedDate,
                },
            });

            if (response.data && typeof response.data !== 'number') {
                setCurrentDayTaskClocking(response.data);
            }
        } catch (error) {
            console.error("Error fetching clocking data:", error);
        }
    };

    const getTotalHoursForWorkerInSelectedDate = async (userId: string) => {
        const formattedDate = selectedDate?.format("YYYY-MM-DD");
        const response = await triggerGetClocking({
            clockingParams: {
                workerId: userId,
                startDate: formattedDate,
                endDate: formattedDate,
            },
        });
        if (response.data) {
            setUserTotalHours((prev) => {
                return {
                    ...prev,
                    [userId]: typeof response.data === 'number' ? response.data : 0,
                };
            });
        }
    };

    const { data: freeDaysData, isLoading: isLoadingFreeDays, isSuccess: isSuccessFreeDays } = useGetFreeDaysQuery(
        { freeDayParams },
        { refetchOnMountOrArgChange: true }
    );
    useEffect(() => {
        if (isSuccessFreeDays && freeDaysData) {
            users.forEach((user) => getTotalHoursForWorkerInSelectedDate(user.id));
            setSelectedDates(freeDaysData);
        }

    }, [freeDaysData]);

    const disabledDate = (current: Dayjs) => {

        if (current < startDate || current > endDate) {
            return true;
        }
        const isDateDisabled = selectedDates.some(selectedDate =>
            dayjs(selectedDate.date).isSame(current, 'day')
        );
        return isDateDisabled;
    };

    const headerRender = () => {
        return <>
            <span>{startDate.format("YYYY-MMMM")}</span>
            <br />
            <span>Task: {task?.title}</span>
            <br />
            <br />

        </>;
    };

    const dateCellRender = (value: Dayjs) => {

        const outsideDate = value < startDate || value > endDate;
        const isSelected = selectedDate?.isSame(value, 'day');
        const isDisabled = disabledDate(value);

        return (
            <div
                onClick={() => !outsideDate && handleDateSelect(value)}
                style={{
                    backgroundColor: isSelected ? '#baece1' : 'white',
                    color: isDisabled ? 'red' : '#00b197',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '32px',
                    height: '32px',
                    cursor: outsideDate ? 'default' : 'pointer',
                    borderRadius: '50%',
                    margin: 'auto',
                    visibility: outsideDate ? 'hidden' : 'visible',
                }}
            >
                {!outsideDate ? value.date() : ''}
            </div>
        );
    };

    const handleSubmit = (values: FormValues) => {
        const formattedDate = selectedDate ? selectedDate.format('YYYY-MM-DD') : "";
        const parsedInputs: CreateClockingDto[] = users.map((user) => {
            const hoursWorked = parseFloat(values[`hoursWorked_${user.id}`] as string);
            return {
                workerId: user.id,
                date: formattedDate,
                hoursWorked: !Number.isNaN(hoursWorked) ? hoursWorked : currentDayTaskClocking?.find((clocking: ClockingDto) => clocking.workerId === user.id)?.hoursWorked ?? 0,
            }
        });
        handleAddClockings(parsedInputs);
        //onSubmit(parsedInputs, formattedDate);
        getClockingData(formattedDate);
    };

    const getClockingBreakdown = (): MenuProps['items'] => {
        const breakdown: MenuProps['items'] = [];
        return breakdown;
    };

    const handleTotalHoursClicked = (userId: string) => {
    }

    return (
        <ModalComponent<FormValues>
            open
            onSubmit={handleSubmit}
            name="Add Clocking"
            onClose={onClose}
        >
            <Form.Item
                name="calendar"
                rules={[
                    {
                        validator: (_, value) => {
                            if (selectedDate) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Please select a date'));
                        },
                    },
                ]}>
                <CalendarComponent
                    disabledDate={disabledDate}
                    dateCellRender={dateCellRender}
                    headerRender={headerRender}
                    month={startDate}
                />
            </Form.Item>
            <br />
            <Row key="header" justify="space-between" gutter={10}>
                <Col span={4}>
                    Worker
                </Col>
                <Col span={2}>
                    Total
                </Col>
                <Col span={3}>
                    Current
                </Col>
                <Col span={4}>
                    Clocking
                </Col>
            </Row>
            <hr />
            {
                users.map((user) => (
                    <Row key={user.id} justify="space-between">

                        <Col span={5}>
                            <span>{`${user.firstName} ${user.lastName}`}</span>
                        </Col>

                        <Dropdown
                            menu={{
                                items: getClockingBreakdown()
                            }}
                            trigger={['click']}
                            placement="topLeft"
                        >
                            <Col span={2} onClick={() => handleTotalHoursClicked(user.id)}>
                                <span>{userTotalHours[user.id] ?? 0}</span>
                            </Col>

                        </Dropdown>
                        <Col span={1}>
                            <span>{`${currentDayTaskClocking?.find((clocking: ClockingDto) => clocking.workerId === user.id)?.hoursWorked ?? 0}`}</span>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                name={`hoursWorked_${user.id}`}
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            const numberValue = Number(value);
                                            if (!value || (numberValue >= 0 && numberValue <= 12)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Please enter a value between 0 and 12'));
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    type="number"
                                    placeholder="Update hours"
                                    min={0}
                                    max={12}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                ))
            }
        </ModalComponent >
    );
};

export default AddClockingModal;
