import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authApi } from '../auth/api/AuthApi';
import { authSlice } from '../auth/api/AuthSlice';
import { projectsApi } from '../projects/api/ProjectApi';

const persistConfig = {
    key: 'root',
    blacklist: [projectsApi.reducerPath],
    storage
};

const rootReducer = combineReducers({
    authentication: authSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false
        })
            .concat(
                authApi.middleware,
                projectsApi.middleware
            )
});

const persistor = persistStore(store);


export type RootState = ReturnType<typeof store.getState>;
export const getCurrentUserEmail = (state: RootState) => state.authentication.userEmail;
export const getCurrentUserRole = (state: RootState) => state.authentication.role;
export { store, persistor };
