import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {  Card, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useAddProjectMutation, useDeleteProjectMutation, useGetProjectsQuery, useUpdateProjectMutation } from "projects/api/ProjectApi";
import { UpdateProjectDto, CreateProjectDto, PageRequestDto, ProjectDto, RoleDto, ProjectFilterParams } from "shared/generated-sources";
import { RoutePathKeys } from "shared/constants/RoutePathKeys.enum";
import { useModal } from "shared/contexts/ModalContext";

import AddProjectModal from "components/modals/AddProjectModal";
import UpdateProjectModal from "components/modals/UpdateProjectModal";
import TableComponent from "components/TableComponent";

import { PROJECT_LIST_COLUMN_LAYOUT } from "shared/constants/tableColumnProps";

const ProjectListPage = () => {

    const [projects, setProjects] = useState<ProjectDto[]>([]);
    const { showModal, hideModal } = useModal();
    const [addProject] = useAddProjectMutation();
    const [deleteProject] = useDeleteProjectMutation();
    const [updateProject] = useUpdateProjectMutation();

    const navigate = useNavigate();
    const location = useLocation();
    
    const pageRequest: PageRequestDto = {
        pageNumber: 0,
        pageSize: 15,
        sortBy: 'startDate',
        sortDirection: PageRequestDto.sortDirection.ASC
    }

    const filterParams: ProjectFilterParams = {
        director: location.pathname.includes('/projects/director'),
        worker: location.pathname.includes('/projects/worker'),
    };

    const { data, isLoading, isSuccess } = useGetProjectsQuery({
        pageRequest,
        filterParams
    },
        { refetchOnMountOrArgChange: true });

    useEffect(() => {
        if (isSuccess) {
            setProjects(data.data || []);
            console.log(data.data);
        }
    }, [isSuccess, data]);


    const findProjectById = (projectId: string): UpdateProjectDto => {
        return projects.filter(project => project.id === projectId)[0];
    }

    const handleRowClick = (record: { id: string }) => {
        navigate(`${RoutePathKeys.projects}/${record.id}`);
    };

    const handleAddProject = async (values: CreateProjectDto) => {

        const project = await addProject(values)
        setProjects([project.data as ProjectDto, ...projects])
    }

    const handleProjectEdit = async (projectId: string, updatedProjectDto: UpdateProjectDto) => {

        const response = await updateProject({ projectId, updatedProjectDto })
        let currentProjects = [response.data as ProjectDto, ...projects.filter(project => project.id !== projectId)]
        setProjects(currentProjects)
    }

    const handleProjectDelete = async (record: { id: string }) => {

        const response = await deleteProject({ projectId: record.id })
        if (response.data?.success) {
            setProjects(projects.filter(p => p.id !== record.id))
        }
    }


    const openAddProjectModal = () => {
        showModal(
            'addProjectModal',
            <AddProjectModal
                onClose={() => hideModal("addProjectModal")}
                onSubmit={(values) => {
                    handleAddProject(values)
                    hideModal("addProjectModal");
                }}
            />
        );
    };

    const openUpdateProjectModal = (record: { id: string }) => {

        let currentProject: UpdateProjectDto = findProjectById(record.id);
        showModal(
            'updateProjectModal',
            <UpdateProjectModal
                onClose={() => hideModal("updateProjectModal")}
                onSubmit={(values) => {
                    handleProjectEdit(record.id, values);
                    hideModal("updateProjectModal");
                }}
                initialValues=
                {{
                    name: currentProject ? currentProject.name : '',
                    startDate: currentProject ? currentProject.startDate : '',
                    endDate: currentProject ? currentProject.endDate : '',
                    status: currentProject ? currentProject.status : ProjectDto.status.INACTIVE,
                    allocatedPersonMonths: currentProject ? currentProject.allocatedPersonMonths : 0
                }}
            />
        );
    };

    if (isLoading) {
        return <Spin indicator={<LoadingOutlined spin />} size="large" />
    }

    return (
        <Card bordered={false} >
            <TableComponent
                pagination={{ current: data ? data!.currentPage : 0, total: data ? data!.totalPages : 0 }}
                data={data ? projects : []}
                columns={PROJECT_LIST_COLUMN_LAYOUT}
                onRowClick={handleRowClick}
                onAdd={openAddProjectModal}
                tooltip="project"
                isWorkerViewing={!location.pathname.includes('/projects/director')}
                onEdit={openUpdateProjectModal}
                onDelete={handleProjectDelete}
            />
        </Card>
    )
}

export default ProjectListPage;