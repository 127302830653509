import { Button, Card, Tooltip } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import { getCurrentUserRole } from "store/store";
import { CreateUserDto, PageRequestDto, RoleDto, UserDto } from "shared/generated-sources";
import { useAddUserMutation, useGetUsersQuery } from "projects/api/ProjectApi";
import { useModal } from "shared/contexts/ModalContext";

import AddUserToPlatformModal from "components/modals/AddUserToPlatformModal";
import TableComponent from "components/TableComponent";
import { useEffect, useState } from "react";
import { USER_LIST_COLUMN_LAYOUT } from "shared/constants/tableColumnProps";



const UsersPage = () => {

    const { showModal, hideModal } = useModal();
    const [addUser] = useAddUserMutation();

    const [users, setUsers] = useState<UserDto[]>([]);
    const userRole: string = useSelector(getCurrentUserRole)[0];

    const { data, isLoading, isSuccess } = useGetUsersQuery({
        pageRequest: { pageNumber: 0, pageSize: 10, sortBy: "firstName", sortDirection: PageRequestDto.sortDirection.ASC },
    }, { refetchOnMountOrArgChange: true });

    useEffect(() => {
        if (isSuccess) {
            setUsers(data.data || [])
        }
    }, [isSuccess, data]);

    const handleAddUserToPlatform = async (values: CreateUserDto) => {

        const user = await addUser(values);
    }

    const openAddUserToPlatformModal = () => {
        showModal(
            'addUserToPlatformModal',
            <AddUserToPlatformModal
                userRole={userRole}
                onClose={() => hideModal("addUserToPlatformModal")}
                onSubmit={(values) => {
                    handleAddUserToPlatform(values)
                    hideModal("addUserToPaddUserToPlatformModallatform");
                }}
            />
        );
    };
    return (
        <Card bordered={false}>
            <TableComponent
                data={data ? users : []}
                columns={USER_LIST_COLUMN_LAYOUT}
                onAdd={openAddUserToPlatformModal}
                tooltip="user"
            />
        </Card>

    )
}

export default UsersPage;

