import { Calendar } from "antd"
import { Dayjs } from "dayjs";
import 'dayjs/locale/ro';
import locale from 'antd/es/date-picker/locale/ro_RO';

interface CalendarProps {
    month?: Dayjs;
    headerRender: ({ value, onChange }: { value: Dayjs; onChange: (date: Dayjs) => void }) => React.ReactNode;
    dateCellRender: (value: Dayjs) => React.ReactNode;
    disabledDate?: (current: Dayjs) => boolean;
}

const CalendarComponent: React.FC<CalendarProps> = ({ month, headerRender, disabledDate, dateCellRender }) => {

    return (
        <Calendar
            value={month}
            locale={locale}
            fullCellRender={dateCellRender}
            headerRender={headerRender}
            disabledDate={disabledDate}
            fullscreen={false}
        />)
}

export default CalendarComponent;