import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import AddClockingModal from "./modals/AddClockingModal";
import { CreateClockingDto, CreateTaskDto, TaskDto } from "shared/generated-sources";
import { useModal } from "shared/contexts/ModalContext";
import dayjs from "dayjs";
import { useAddClockingsMutation } from "projects/api/ProjectApi";

interface GanttChartProps {
  startDate: string;
  endDate: string;
  currentTask: TaskDto | null;
  projectId: string
  workPackageId: string;
};

const GanttChart: React.FC<GanttChartProps> = ({
  startDate,
  endDate,
  currentTask,
  projectId,
  workPackageId
}) => {

  const { showModal, hideModal } = useModal();
  const [addClocking] = useAddClockingsMutation();

  const handleAddClockings = async (taskId: string, date: string, clocking: CreateClockingDto[]) => {
    const badClockings = await addClocking({ projectId: projectId, workPackageId: workPackageId, taskId: currentTask ? currentTask.id : "", clockings: clocking });
  }
  const openAddClockingModal = (startDate: Date, endDate: Date) => {
    showModal(
      "addClockingModal",
      <AddClockingModal
        startDate={dayjs(startDate)}
        endDate={dayjs(endDate)}
        users={currentTask ? currentTask.workers : []}
        task={currentTask}
        projectId={projectId}
        workPackageId={workPackageId}
        onClose={() => hideModal("addClockingModal")}
        onSubmit={(values, date) => {
          handleAddClockings(currentTask ? currentTask.id : '', date, values)
        }}
      />
    );
  };
  const svgRef = useRef<SVGSVGElement | null>(null);

  const getMonthsBetween = (start: Date, end: Date) => {
    const months = [];
    let date = new Date(start);
    while (date <= end || date.getMonth() < end.getMonth()) {
      months.push(new Date(date));
      date.setMonth(date.getMonth() + 1);
    }
    return months;
  };
  const getMonthDateRange = (month: Date, highlightStart: Date | null, highlightEnd: Date | null) => {
    const monthStart = new Date(month.getFullYear(), month.getMonth(), 1);
    const monthEnd = new Date(month.getFullYear(), month.getMonth() + 1, 0);

    const startDate =
      highlightStart && highlightStart.getMonth() === month.getMonth()
        ? highlightStart
        : monthStart;

    const endDate =
      highlightEnd && highlightEnd.getMonth() === month.getMonth()
        ? highlightEnd
        : monthEnd;

    return { startDate, endDate };
  };


  useEffect(() => {
    if (!svgRef.current) return;

    const start = new Date(startDate);
    const end = new Date(endDate);
    const highlightStart = currentTask?.startDate ? new Date(currentTask.startDate) : null;
    const highlightEnd = currentTask?.endDate ? new Date(currentTask.endDate) : null;

    const months = getMonthsBetween(start, end);
    const monthWidth = 50;
    const totalWidth = months.length * monthWidth;
    const height = 50;

    const isHighlightedMonth = (month: Date) => {
      if (!highlightStart || !highlightEnd) return false;
      const monthStart = new Date(month.getFullYear(), month.getMonth(), 1);
      const monthEnd = new Date(month.getFullYear(), month.getMonth() + 1, 0);
      return (
        (highlightStart <= monthEnd && highlightEnd >= monthStart) // Month overlaps with highlight range
      );
    };

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    svg.attr("width", totalWidth);

    svg.selectAll(".month")
      .data(months)
      .enter()
      .append("rect")
      .attr("class", "month")
      .attr("x", (d, i) => i * monthWidth)
      .attr("y", 0)
      .attr("width", monthWidth)
      .attr("height", height)
      .attr("fill", (d) => (isHighlightedMonth(d) ? "#baece1" : "white"))
      .attr("stroke", "black")
      .on("mouseover", function (event, d) {
        d3.select(this).attr("fill", () => (isHighlightedMonth(d) ? "#00b197" : "white"));
      })
      .on("mouseout", function (event, d) {
        d3.select(this).attr("fill", (isHighlightedMonth(d) ? "#baece1" : "white"));
      })
      .on("click", (event, d) => {
        if (isHighlightedMonth(d)) {
          const { startDate, endDate } = getMonthDateRange(d, highlightStart, highlightEnd);
          openAddClockingModal(startDate, endDate);
        }
      });
  }, [startDate, endDate, currentTask?.startDate, currentTask?.endDate]);


  return (
    <svg ref={svgRef} height={50}></svg>
  );
};

export default GanttChart;
