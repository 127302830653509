import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from "react-redux";

import { isAuthenticated } from "auth/api/AuthSlice";
import { persistor, store } from "./store/store";
import { RoutePathKeys } from "shared/constants/RoutePathKeys.enum";


import LoginPage from "pages/LoginPage";
import DashboardPage from "pages/DashboardPage";
import ProjectListPage from "pages/ProjectListPage";
import HomePage from "pages/HomePage";
import ProjectPage from "pages/ProjectPage";
import ProfilePage from 'pages/ProfilePage';
import AccountantPage from 'pages/AccountantPage';
import PasswordResetRequestPage from 'pages/PasswordResetRequestPage';
import PasswordResetPage from 'pages/PasswordResetPage';
import UsersPage from 'pages/UsersPage';

import UnauthorizedLayoutComponent from "components/PublicLayoutComponent";
import AuthorizedLayoutComponent from "components/AuthorizedLayoutComponent";

import './App.css';

interface PrivateRouteProps {
    element: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
    return isAuthenticated(store.getState()) ? element : <Navigate to="/login" />;
};

const App: React.FC = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <Routes>
                        <Route path={RoutePathKeys.homepage} element={<UnauthorizedLayoutComponent />}>
                            <Route index element={<HomePage />} />
                            <Route path={RoutePathKeys.login} element={<LoginPage />} />
                            <Route path={RoutePathKeys.passwordResetRequest} element={<PasswordResetRequestPage />} />
                            <Route path={RoutePathKeys.passwordReset} element={<PasswordResetPage />} />
                            {/*<Route path="register" element={<Register />} />*/}
                            {/*<Route path="unauthorized" element={<Unauthorized />} />*/}
                        </Route>
                        <Route element={<PrivateRoute element={<AuthorizedLayoutComponent />} />}>
                            <Route index path={RoutePathKeys.dashboard} element={<DashboardPage />} />
                            <Route index path={RoutePathKeys.profile} element={<ProfilePage />} />
                            <Route path={RoutePathKeys.accountant} element={<AccountantPage />} />
                            <Route path={RoutePathKeys.users} element={<UsersPage />} />
                            <Route path={RoutePathKeys.workerProjects} element={<ProjectListPage />} />
                            <Route path={RoutePathKeys.directorProjects} element={<ProjectListPage />} />
                            <Route path={RoutePathKeys.projects} element={<ProjectListPage />} />
                            <Route path={RoutePathKeys.project} element={<ProjectPage />} />

                        </Route>
                    </Routes>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    )
}

export default App;
