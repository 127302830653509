import {Link} from 'react-router-dom';
import {Col, Row, Space, Typography} from "antd";
import {Footer} from "antd/es/layout/layout";
import {FacebookOutlined, InstagramOutlined, TwitterOutlined} from '@ant-design/icons';


import {RoutePathKeys} from "shared/constants/RoutePathKeys.enum";

const {Title, Paragraph} = Typography;

const FooterComponent = () => {

    return (
            <Footer style={footer}>
                <Row justify="center" style={footerContent}>
                    <Col xs={24} sm={12} md={8} style={footerColumn}>
                        <Title level={4} style={footerTitle}>Company</Title>
                        <Paragraph style={footerText}>
                            &copy; {new Date().getFullYear()} Your Company. All rights reserved.
                        </Paragraph>
                    </Col>
                    <Col xs={24} sm={12} md={8} style={footerColumn}>
                        <Title level={4} style={footerTitle}>Navigation</Title>
                        <Space direction="vertical">
                            <Link to={RoutePathKeys.homepage}>Home</Link>
                            <Link to={RoutePathKeys.login}>Login</Link>
                        </Space>
                    </Col>
                    <Col style={footerColumn}>
                        <Title level={4} style={footerTitle}>Follow Us</Title>
                        <Space size="large">
                            <Link to="#"><FacebookOutlined/></Link>
                            <Link to="#"><TwitterOutlined/></Link>
                            <Link to="#"><InstagramOutlined/></Link>
                        </Space>
                    </Col>
                </Row>
            </Footer>
    )
}

export default FooterComponent;


const footer = {
    backgroundColor: "#001529",
    color: "white",
    padding: "40px 0"
}

const footerContent = {
    maxWidth: "1200px",
    margin: "0 auto"
}

const footerColumn = {
    marginBottom: "20px"
}

const footerTitle = {
    color: "white"
}

const footerText = {
    color: "rgba(255, 255, 255, 0.65)"
}
