import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Button } from 'antd';

import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import CalendarComponent from 'components/CalendarComponent';

import { useAddFreeDayMutation, useDeleteFreeDayMutation, useGetFreeDaysQuery } from 'projects/api/ProjectApi';
import { CreateFreeDayDto, FreeDayDto, FreeDayParamDto } from 'shared/generated-sources';

const AccountPage: React.FC = () => {

    const [currentMonth, setCurrentMonth] = useState<Dayjs>(dayjs());
    const [selectedDates, setSelectedDates] = useState<FreeDayDto[]>([]);

    const [addFreeDay] = useAddFreeDayMutation();
    const [deleteFreeDay] = useDeleteFreeDayMutation();

    const freeDayParams: FreeDayParamDto = {

        month: (currentMonth.month() + 1),
        year: currentMonth.year(),
    }

    const { data, isLoading, isSuccess } = useGetFreeDaysQuery({
        freeDayParams
    },
        { refetchOnMountOrArgChange: true });

    useEffect(() => {
        if (isSuccess && data) {
            setSelectedDates(data);
        }
    }, [data, isSuccess]);

    const handleAddFreeDay = async (freeDayToAdd: CreateFreeDayDto) => {

        const freeDay = await addFreeDay(freeDayToAdd)
        setSelectedDates([freeDay.data as FreeDayDto, ...selectedDates]);
    }

    const handleDeleteFreeDay = async (id: string) => {

        const freeDay = await deleteFreeDay({ freeDayId: id })
        setSelectedDates(selectedDates.filter(date => date.id !== id))
    }

    const toggleDateSelection = (date: Dayjs) => {

        const foundDate = selectedDates.find(freeDay => freeDay.date === date.format("YYYY-MM-DD"))
        if (foundDate) {
            handleDeleteFreeDay(foundDate.id!)
        }
        else {
            handleAddFreeDay({ freeDay: date.format("YYYY-MM-DD") })
        }
    };

    const dateCellRender = (value: Dayjs) => {
        const isSameMonth = value.month() === currentMonth.month();
        const isSelected = selectedDates?.some(selectedDate => selectedDate.date === value.format("YYYY-MM-DD"));
        return isSameMonth ? (
            <div
                onClick={() => toggleDateSelection(value)}
                style={{
                    color: isSelected ? 'red' : '#00b197',
                    textAlign: 'center',
                    cursor: 'pointer'
                }}
            >
                {value.date()}
            </div>
        ) : null;
    };

    const headerRender = ({ value, onChange }: { value: Dayjs; onChange: (date: Dayjs) => void }) => {

        const year = value.year();
        const month = value.month();

        return (
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <Button type="primary" onClick={() => { onChange(value.subtract(1, 'month')); setCurrentMonth(value.subtract(1, 'month')) }}>
                    <LeftOutlined />
                </Button>
                <span>
                    {value.format("YYYY-MMMM")}
                </span>
                <Button type="primary" onClick={() => { onChange(value.add(1, 'month')); setCurrentMonth(value.add(1, 'month')) }}>
                    <RightOutlined />
                </Button>
            </div>
        );
    };

    const disabledDate = (current: Dayjs) => {
        const isOutsideCurrentMonth = current.month() !== currentMonth.month() || current.year() !== currentMonth.year();
        const isWeekend = current.day() === 0 || current.day() === 6;

        return isOutsideCurrentMonth || isWeekend;
    };

    return (
        <div style={{ padding: '20px' }}>
            <CalendarComponent
                dateCellRender={dateCellRender}
                headerRender={headerRender}
                month={currentMonth}
                disabledDate={disabledDate} />
        </div>
    );
};

export default AccountPage;