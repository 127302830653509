import React from 'react';
import { Form, Input, Select } from 'antd';

import { enumToOptions } from 'shared/constants/enumFunctions';
import { TaskDto, UpdateTaskDto } from 'shared/generated-sources';

import ModalComponent from 'components/ModalComponent';

interface UpdateTaskModalProps {
    projectStartDate: string;
    projectEndDate: string;
    onSubmit: (values: UpdateTaskDto) => void;
    onClose: () => void;
    initialValues: UpdateTaskDto;
}

const UpdateTaskModal: React.FC<UpdateTaskModalProps> = ({ projectEndDate, projectStartDate, onSubmit, onClose, initialValues }) => {

    return (
        <ModalComponent<UpdateTaskDto>
            open
            name="Update Task"
            onClose={onClose}
            onSubmit={onSubmit}
            initialValues={initialValues}
        >
            <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Please input a name!' }]}
            >
                <Input type={"string"} />
            </Form.Item>
            <Form.Item
                name="description"
                label="Description"
                rules={[{ required: false, message: 'Type in a description' }]}
            >
                <Input type={"string"} />
            </Form.Item>
            <Form.Item
                name="startDate"
                label="Start date"
                rules={[{ required: true, message: 'Choose a date', type: 'date' }]}
            >
                <Input type={"date"}
                    min={projectStartDate}
                    max={projectEndDate} />
            </Form.Item>
            <Form.Item
                name="endDate"
                label="End date"
                rules={[{ required: true, message: 'Choose a date', type: 'date' }]}
            >
                <Input type={"date"}
                    min={projectStartDate}
                    max={projectEndDate} />
            </Form.Item>
            <Form.Item
                name="status"
                label="Status"
                rules={[{ required: true, message: 'Choose a status', type: "string" }]}
            >
                <Select options={[...enumToOptions({
                    enumObj: TaskDto.status
                })
                ]} />
            </Form.Item>
            <Form.Item
                name="reference"
                label="reference"
                style={{ display: 'none' }}
            >
                <Input />
            </Form.Item>
        </ModalComponent>
    );
};

export default UpdateTaskModal;