export enum RoutePathKeys {
    homepage = '/',
    login = '/login',
    profile = '/profile',
    users = '/users',
    accountant = '/accountant',
    dashboard = '/dashboard',
    directorProjects = '/projects/director',
    workerProjects = '/projects/worker',
    projects = '/projects',
    project = '/projects/:id',
    passwordResetRequest = "/auth/passwordResetRequest",
    passwordReset = "/auth/passwordReset"
}
