import { RoleDto } from "shared/generated-sources";

export const PROJECT_LIST_COLUMN_LAYOUT = [
    {
        title: 'Project',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Person Months',
        dataIndex: 'numberOfPersonMonths',
        key: 'numberOfPersonMonths',
    },
    {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
    },
    {
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
    },
]
export const USER_LIST_COLUMN_LAYOUT = [
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Role',
        render: (text: string, record: { role: RoleDto }) => {
            const roleDisplayNames: Record<RoleDto.name, string> = {
                [RoleDto.name.WORKER]: 'Worker',
                [RoleDto.name.DIRECTOR]: 'Director',
                [RoleDto.name.ACCOUNTANT]: 'Accountant',
                [RoleDto.name.ADMIN]: 'Administrator',
            };
            return roleDisplayNames[record.role.name] || 'Unknown Role';
        },
        key: 'role',
    },
    {
        title: 'Name',
        render: (text: string, record: { firstName: string; lastName: string }) =>
            `${record.firstName} ${record.lastName}`,
        key: 'name',
    },
]

